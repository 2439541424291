import {SimpleShowLayout} from "react-admin";
import React, { useEffect, useState } from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import {Error, Loading, sanitizeFieldRestProps} from "ra-ui-materialui";
import {Box, List, ListItem} from "@material-ui/core";
import {Record, useDataProvider, useRecordContext, useTranslate} from "ra-core";
import {InstrumentsFieldValues, WorkgroupsFieldValues} from "../../types";

export const OtherOptions = (props: any) => {
  const {record} = props;
  const options = get(record, 'options');
  if (options === undefined) {
    return null;
  }

  return (<SimpleShowLayout {...props}>
    <Typography
      variant="h6"
      component="span"
      align="center"
    >Tábor specifikus opciók</Typography>
    <JoinToTheCampAt/>
    <CampTransferOption/>
    <RegosFieldSet/>
  </SimpleShowLayout>);
};

const FullOutFit ={
  1: 'Igen',
  0: 'Nincs',
  2: 'Nincs, de van lehetőség beszerezni'
}

function RegosFieldSet() {
  const record = useRecordContext();
 
  const dataProvider = useDataProvider();
  const [workgroups, setWorkgroups] = useState<Record | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
   //@ts-ignore   
   dataProvider.getOne('camp_options', {type:'regos.workgroups'})
          .then(({ data }: { data: Record }) => {
             setWorkgroups(data);
             setLoading(false);
          })
          .catch(error => {
              setError(error);
              setLoading(false);
          })
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error  error={error}/>
  if (!workgroups) return null;

const regos = get(record, 'options.regos');
if (!regos) {
  return null;
}
const selectedWorkgroups = get(regos, 'workgroups', []);
const instruments = get(regos, 'instruments', []);
  return (
  <Box p="1em">
    <Typography component="h6">Milyen munkacsoporton szeretnél résztvenni? Tedd sorrendbe prioritás szerint a munkacsoportokat!</Typography>
    <List>
      {selectedWorkgroups.map(item => (<ListItem key={item}>{workgroups.values[item]}</ListItem>))}
    </List>
    <Typography component="h6">Milyen hangszeren játszol?</Typography>
    <List>
    {instruments.map(item => (<ListItem key={item}>{InstrumentsFieldValues[item]?.name??item}</ListItem>))}
    </List>
    <Typography component="h6">Van viseleted?</Typography>
    <List>
      <ListItem>{FullOutFit[record.options?.regos?.full_outfit]}</ListItem>
    </List>
  </Box>)
}

const CampTransferOption = (props: any) => {
  const {
    className, value, record, ...rest
  } = props;
  const transfers = get(record, 'options.transfers', {});
  const {arrival, departure} = transfers;
  if (!arrival && !departure) {
    return null;
  }
  return (<Box p="1em">
    <Typography component="h6">Transzfer</Typography>
    {!transfers.ask ? <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeFieldRestProps(rest)}
    > Nem kérek transzfert</Typography> : <>
      {transfers.arrival && <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeFieldRestProps(rest)}
      >Érkezés: {transfers.arrival}</Typography>}
      {transfers.departure && <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeFieldRestProps(rest)}
      >Távozás: {transfers.departure}</Typography>}
    </>}


  </Box>)
}

const JoinToTheCampAt = (props: any) => {
  const t = useTranslate();
  const {record} = props;
  const joinAtStart = get(record, 'options.start_available');
  const joinAt = get(record, 'options.start');
  if (joinAtStart === undefined) {
    return null;
  }

  return (<Box p="1em">
    <Typography component="h6">Táborba érkezés</Typography>
    <List>
      <ListItem>{joinAtStart ? t('resources.applications.options.join.atStart') : `${t('resources.applications.options.join.at')}<br/>${joinAt}`}</ListItem>
    </List>

  </Box>)
}
