// src/pages/camps/components/CampRegosOptionsEdit.tsx
import {useState, useEffect} from "react";
import { Box, List, ListItem, Paper, Typography } from "@material-ui/core";
import {
    BooleanInput,
    FormDataConsumer,
    useDataProvider,
    Loading,
    Error,
} from "react-admin";

function RegosFieldsInfo({ workgroups }: { workgroups: { id: string; name: string }[] }) {
    return (
        <>
            <Typography>
                Milyen munkacsoporton szeretnél résztvenni? (Nem tudjuk a kívánt
                munkacsoporton való részvételt garantálni)?:
            </Typography>
            <List>
            {workgroups.map(item => (
                <ListItem key={item.id}>
                   - {item.name}
                </ListItem>
            ))}
            <ListItem>Ha játszol hangszeren, milyen(ek)en?</ListItem>
            <ListItem>Tudsz hozni magadnak egész viseletet a táborba?</ListItem>
        </List>
        </>
    );
}

export function CampRegosOptionsEdit() {
    const dataProvider = useDataProvider();
    const [workgroupOptions, setWorkgroupOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        dataProvider
            // @ts-ignore
            .getOne("camp_options", { type: "regos.workgroups" }) // Assuming a single record with ID 'regos.workgroups'
            .then(({ data }) => {
                setWorkgroupOptions(data.values || []); // Access the 'values' field from the JSON
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, [dataProvider]);

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <Error error={error} />;
    }

  const workgroups = Object.keys(workgroupOptions).map(wg => ({ id: wg, name: workgroupOptions[wg] }));
  return (
    <Paper>
      <Box p="1em">
        <Typography variant="h6">Regős tábori lehetőségek</Typography>
        <BooleanInput
          source="options.regos.enabled"
          label="Ez egy regőstábor?"
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.options?.regos?.enabled && (
              <RegosFieldsInfo workgroups={workgroups} />
            )
          }
        </FormDataConsumer>
      </Box>
    </Paper>
  );
}

/**
 { id: 'faragas', name: 'fafaragás' },
 { id: 'fazekassag', name: 'fazekasság' },
 { id: 'citera', name: 'citeratanulás (kezdő + haladó, nem kötelező a saját hangszer)' },
 { id: 'vonos', name: 'hegedű-, brácsa- és bőgőoktatás (nem kezdőknek, csak saját hangszerrel)' },
 { id: 'nemez', name: 'nemezelés' },
 { id: 'furulya', name: 'furulyázás (kezdőknek is)' },

 - fafaragás
 - fazekasság
 - citeratanulás (kezdő + haladó, nem kötelező a saját hangszer)
 - hegedű-, brácsa- és bőgőoktatás (nem kezdőknek, csak saját hangszerrel)
 - nemezelés
 - talán furulyázás (kezdőknek is)

 */
